<template>
  <div v-loading="loading" id="page"></div>
</template>
<script>
import * as keyConvertUtils from "@/views/NEW/module/spec/config/jsonKeyConverter";
export default {
  name: "ERP-PreviewPDF",
  data() {
    return {
      loading:true
    };
  },
  created() {
    let itemCode = this.$route.query.itemCode;
    let productListType = this.$route.query.type;
    let category = this.$route.query.category;
    let language = this.$route.query.language;
    let params = {
      itemCode: itemCode,
      productListType: productListType,
      category:category,
      language:language
    };
    this.loadData(params);
  },
  mounted() {},
  methods: {
    loadData(params) {
      // spec: http://localhost:8083/#/erp-preview?itemCode=SDNP-P-C-011&productListType=products&category=spec&language=EN
      // sds: http://localhost:8083/#/erp-preview?itemCode=REXT-S-004&productListType=products&category=sds&language=EN
      // cn-sds: http://localhost:8083/#/erp-preview?itemCode=TYO-S-003&productListType=products&category=sds&language=CN
      
      let api = "";
      if(params.category=='spec'){
        api = this.$api.handleSpec.getNewSpecAuditedLatestInfo(params)
      }else if(params.category=='sds'){
        if(params.language=='EN'){
          api = this.$api.handleNewSds.getNewSdsAuditedLatestInfo(params);
        }else{
          api = this.$api.handleNewSds.getNewCnSdsAuditedLatestInfo(params);
        }
        
      }else{
        api = this.$api.handleSpec.getNewSpecAuditedLatestInfo(params)
      }
      
      api.then((res) => {
        let data = res;
        if(params.category=='sds'){
          data = keyConvertUtils.convertJsonKeysToSnakeWithLodash(data);
          let jsonValueObj = keyConvertUtils.convertJsonKeysToSnakeWithLodash(JSON.parse(res.jsonValue));
          let jsonValueStr = JSON.stringify(jsonValueObj);
          data.json_value = jsonValueStr;
          console.log('data:',data)
        }else{
          data.json_value=data.jsonValue;
          data.item_code = data.itemCode;
        }
        this.previewHandle(data);
      });
    },
    previewHandle(data) {
      let category = this.$route.query.category;
      let language = this.$route.query.language;
      let lang = language=='CN'?'China':'US';
      
      let params = {
        language: lang,
        print_type: category,
        data: data,
        cutomer_signature: false,
        preview_pdf: false,
        file_name: "erp_" + data.item_code, //item_code+version+signature
        response_type: "text",
      };

      this.$axios
        .post("https://spec.dracoherbs.com/flask/generate_spec", params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let fileUrl = `https://spec.dracoherbs.com/download/${response.data.file_name}`;
          setTimeout(() => {
            this.previewPdfLoading = true;
            window.open(fileUrl);
          }, 200);
        })
        .catch((error) => {
          this.previewPdfLoading = true;
          console.error(error);
        });
    },
  },
};
</script>

<style>
#page{
  height: 100vh;
}
</style>